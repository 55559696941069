/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useWeb3React } from '@web3-react/core';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { StitchedPunkMetadata } from 'web3/dtos';
import { StitchedPunksShop, StitchedPunksShop__factory } from '../../contracts/artifacts/types';
import Layout from '../components/Layout';
import { ICON_EMPTY_COLORED_FILENAME, isValidPunkIndex, STITCHEDPUNKSSHOP_CONTRACT } from '../web3/BlockchainUtils';

export const query = graphql`
    {
        imgIcon: allImageSharp {
            edges {
                node {
                    gatsbyImageData(placeholder: BLURRED, width: 100, height: 100, quality: 75)
                    fluid {
                        originalName
                        originalImg
                    }
                }
            }
        }
    }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Details = ({ data }: { data: any }): JSX.Element => {
    const web3 = useWeb3React();
    const [isLoading, setIsLoading] = useState(true);
    const [isErrorMetaMask, setIsErrorMetaMask] = useState(false);
    const [orderedStitchedPunksList, setOrderedStitchedPunksList] = useState<number[]>([]);
    const [punkDetailPageToJumpTo, setPunkDetailPageToJumpTo] = useState<number>(-1);

    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const stitchedPunkMetadata = require('../stitchedpunks-metadata') as StitchedPunkMetadata[];
    const allStitchedPunks: number[] = stitchedPunkMetadata.map((punk) => Number.parseInt(punk.id));
    const SortFunctionNumber = (a: number, b: number): number => a - b;

    const fetchPunks = useCallback(async () => {
        if (web3.library === undefined || !web3.active || !web3.account) {
            setIsLoading(false);
            setIsErrorMetaMask(true);
            return;
        }

        try {
            setIsLoading(true);
            setIsErrorMetaMask(false);
            console.log('account to show:', web3.account);

            // fetch all StitchedPunks
            const stitchedPunks: number[] = [];

            const shop: StitchedPunksShop = StitchedPunksShop__factory.connect(
                STITCHEDPUNKSSHOP_CONTRACT,
                web3.library,
            );

            // StitchedPunk ordered
            // event OrderCreated(uint16 indexed punkId, address indexed owner);
            // fetch all orders, regardless of owner
            const filter = shop.filters.OrderCreated(null, null);
            const events = await shop.queryFilter(filter, 0, 'latest');

            events.forEach((ev) => {
                console.log('event StitchedPunk OrderCreated:', ev);
                stitchedPunks.push(ev.args.punkId);
            });

            console.log('All StitchedPunk Orders:', stitchedPunks);
            stitchedPunks.sort(SortFunctionNumber);
            setOrderedStitchedPunksList(stitchedPunks);

            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            setIsErrorMetaMask(true);
            console.log('error fetching StitchedPunk orders:', err);
        }
    }, [web3.account, web3.active, web3.library]);

    useEffect(() => {
        fetchPunks();
    }, [fetchPunks]);

    const PunkList = (punkList: number[]): JSX.Element => {
        const rows: JSX.Element[] = [];

        punkList.forEach((punkIndex: number) => {
            const paddedId = punkIndex.toString().padStart(4, '0');
            const imgUrl = '/cryptopunks/punk' + paddedId + '.png';

            // fetch StitchedPunk icon from query with all images
            const imageIcon: JSX.Element[] = [];

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            data.imgIcon.edges.forEach((edge: any) => {
                if (edge.node.fluid.originalName === paddedId + '-icon.jpg') {
                    imageIcon.push(
                        <Link to={'/details/' + punkIndex} key={'sp-icon-' + punkIndex}>
                            <GatsbyImage
                                image={edge.node.gatsbyImageData}
                                alt="Photo of StitchedPunk"
                                className="inverted-hover"
                            />
                        </Link>,
                    );
                }
            });

            // if no StitchedPunk icon was found but SP is already ordered: add colored placeholder
            if (imageIcon.length === 0) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                data.imgIcon.edges.forEach((edge: any) => {
                    if (edge.node.fluid.originalName === ICON_EMPTY_COLORED_FILENAME) {
                        imageIcon.push(
                            <GatsbyImage
                                className="placeholder-icon"
                                image={edge.node.gatsbyImageData}
                                alt="No StitchedPunk created yet"
                                key={'sp-icon-' + punkIndex}
                            />,
                        );
                    }
                });
            }

            rows.push(
                <div className="grid-wrapper punk-card" key={punkIndex}>
                    <div className="col-6 grid-wrapper">
                        <div className="col-12">
                            <h4>CryptoPunk #{punkIndex}</h4>
                        </div>
                        <div className="col-4">
                            <img src={imgUrl} alt={'CryptoPunk #' + punkIndex} className="punk-image pixelated" />
                        </div>
                        <div className="col-8 cryptopunk-details">
                            <ul className="alt">
                                <li>
                                    <a
                                        href={'https://www.larvalabs.com/cryptopunks/details/' + punkIndex}
                                        className="icon fa-external-link">
                                        Show on CryptoPunks Website
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-6 grid-wrapper">
                        <div className="col-12">
                            <h4>StitchedPunk #{punkIndex}</h4>
                        </div>
                        <div className="col-4">{imageIcon}</div>
                        <div className="col-8 stitchedpunks-details">
                            <ul className="alt">
                                <li className="show-details">
                                    <Link
                                        to={'/details/' + punkIndex}
                                        className="link primary button small icon fa-search">
                                        Show Details
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>,
            );
        });

        return <div>{rows}</div>;
    };

    return (
        <Layout>
            <Helmet
                title="StitchedPunks – Overview"
                meta={[{ name: 'description', content: 'StitchedPunks – Overview' }]}
            />

            <div id="details" className="alt">
                <section id="one">
                    <div className="inner">
                        <header className="major">
                            <h1>Overview</h1>
                        </header>
                        <h3>Finished StitchedPunks</h3>
                        <p>
                            These StitchedPunks are already crafted. Take a look at their detail page or the{' '}
                            <Link to="/gallery">gallery</Link> for more photos.
                        </p>
                        <div>{PunkList(allStitchedPunks)}</div>
                        <hr className="major" />
                        <h3>Ordered StitchedPunks</h3>
                        {isLoading && (
                            <p>
                                <span className="loading-animation">Loading...</span>
                            </p>
                        )}
                        {!isLoading && isErrorMetaMask && (
                            <div className="col-12 message-box">
                                <span className="error-message">
                                    <span role="img" aria-label="exclamation-mark icon">
                                        ⚠️
                                    </span>{' '}
                                    Could not connect to Ethereum. Please reconnect in the menu, check your MetaMask and
                                    try again.
                                </span>
                            </div>
                        )}
                        {!isLoading && !isErrorMetaMask && (
                            <div>
                                {orderedStitchedPunksList.length > 0 ? (
                                    <div>
                                        <p>
                                            These StitchedPunks are already ordered. They will be created one by one and
                                            photos are added during the process:
                                        </p>
                                        {PunkList(orderedStitchedPunksList)}
                                    </div>
                                ) : (
                                    <div>
                                        <p>There are no orders yet. Be the first to own a StitchedPunk!</p>
                                        <Link to="/profile" className="button next">
                                            Get your StitchedPunk
                                        </Link>
                                    </div>
                                )}
                            </div>
                        )}
                        <hr className="major" />
                        <h3>View a specific punk</h3>
                        <ul className="actions">
                            <li>Please enter a punk number:</li>
                            <li>
                                <input
                                    type="text"
                                    name="punk-page-to-jump-to"
                                    id="punk-page-to-jump-to"
                                    defaultValue=""
                                    placeholder=""
                                    onChange={(e) => setPunkDetailPageToJumpTo(Number.parseInt(e.target.value))}
                                />
                            </li>
                            <li>
                                <Link
                                    to={
                                        isValidPunkIndex(punkDetailPageToJumpTo)
                                            ? '/details/' + punkDetailPageToJumpTo
                                            : ''
                                    }
                                    className="button small icon fa-search no-border">
                                    Show Details
                                </Link>
                            </li>
                        </ul>
                    </div>
                </section>
            </div>
        </Layout>
    );
};

export default Details;
