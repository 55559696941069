const stitchedPunksMetadata = [];

stitchedPunksMetadata[5708] = {
    id: '5708',
    productionNumber: '3',
    productionDate: '2021-03-07',
    physicalLocation: 'Germany',
    imgIcon: '5708-icon.jpg',
    imgGallery: ['5708-photo-1.jpg', '5708-photo-2.jpg', '5708-photo-3.jpg'],
};

stitchedPunksMetadata[6613] = {
    id: '6613',
    productionNumber: '2',
    productionDate: '2021-03-06',
    physicalLocation: 'Germany',
    imgIcon: '6613-icon.jpg',
    imgGallery: ['6613-photo-1.jpg', '6613-photo-2.jpg', '6613-photo-3.jpg', '6613-photo-4.jpg'],
};

stitchedPunksMetadata[8348] = {
    id: '8348',
    productionNumber: '4',
    productionDate: '2021-03-19',
    physicalLocation: 'Germany',
    imgIcon: '8348-icon.jpg',
    imgGallery: ['8348-photo-1.jpg', '8348-photo-2.jpg', '8348-photo-3.jpg'],
};

stitchedPunksMetadata[9512] = {
    id: '9512',
    productionNumber: '5',
    productionDate: '2021-06-29',
    physicalLocation: '?',
    imgIcon: '9512-icon.jpg',
    imgGallery: [
        '9512-photo-1.jpg',
        '9512-photo-2.jpg',
        '9512-photo-3.jpg',
        '9512-photo-4.jpg',
        '9512-photo-5.jpg',
        '9512-photo-6.jpg',
        '9512-nft.jpg',
    ],
};

stitchedPunksMetadata[9544] = {
    id: '9544',
    productionNumber: '6',
    productionDate: '2021-06-29',
    physicalLocation: '?',
    imgIcon: '9544-icon.jpg',
    imgGallery: [
        '9544-photo-1.jpg',
        '9544-photo-2.jpg',
        '9544-photo-3.jpg',
        '9544-photo-4.jpg',
        '9544-photo-5.jpg',
        '9544-photo-6.jpg',
        '9544-photo-7.jpg',
        '9544-nft.jpg',
    ],
};

stitchedPunksMetadata[3730] = {
    id: '3730',
    productionNumber: '7',
    productionDate: '2021-07-13',
    physicalLocation: '?',
    imgIcon: '3730-icon.jpg',
    imgGallery: [
        '3730-photo-1.jpg',
        '3730-photo-2.jpg',
        '3730-photo-3.jpg',
        '3730-photo-4.jpg',
        '3730-photo-5.jpg',
        '3730-photo-6.jpg',
        '3730-photo-7.jpg',
        '3730-photo-8.jpg',
        '3730-nft.jpg',
    ],
};

module.exports = stitchedPunksMetadata;
